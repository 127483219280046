<template>
  <div>
    <v-form ref="form" lazy-validation @submit.prevent="validate">
      <v-card>
        <v-card-title>Sent Gift</v-card-title>
        <v-card-text>
          <template>
            <Institution :callbackSelect="selectSchool"></Institution>
          </template>
          <template>
            <Students
              :selectedSchool="selectedSchool"
              :callbackSelect="selectStudents"
              ref="dropdown_students"
            ></Students>
          </template>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="$router.go(-1)"> Cancel </v-btn>
          <v-btn color="primary" :loading="api.isLoading" @click="validate">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Institution from "./Institution.vue";
import Students from "./Students.vue";
export default {
  components: {
    Institution,
    Students,
  },
  computed: mapState({
    //
  }),
  props: ["callbackSuccess"],
  data: () => ({
    selectedSchool: null,
    schoolYears: null,
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    students: [],
    selectedStudents: [],
  }),
  created() {
    this.api.url = `${this.$api.servers.event}/api/v1/en/moderator/event/${this.$route.params.parentId}/finisher/sentGift`;

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.callbackSuccess(resp);
    };
  },
  mounted() {},
  methods: {
    selectSchool(school) {
      this.selectedSchool = school;
      var that = this;
      setTimeout(() => {
        that.$refs["dropdown_students"].fetch();
      }, 100);
    },
    selectStudents(students) {
      this.selectedStudents = students;
    },
    validate() {
      this.api.callbackReset();
      if (this.$refs.form.validate()) {
        this.submit();
      } else {
        this.api.callbackError("Please check the form.");
      }
    },
    submit() {
      this.api.params = {
        ids: this.selectedStudents,
      };
      this.$api.fetch(this.api);
    },
  },
};
</script>