<template>
  <v-select
    v-model="selectedStudents"
    :items="students"
    label="Select Students"
    item-value="id"
    item-text="name"
    multiple
    outlined
    :loading="api.isLoading"
  >
    <template v-slot:prepend-item>
      <v-list-item ripple @mousedown.prevent @click="toggle">
        <v-list-item-action>
          <v-icon :color="selectedStudents.length > 0 ? 'indigo darken-4' : ''">
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> Select All </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
  </v-select>
</template>

<script>
export default {
  props: ["selectedSchool","callbackSelect"],
  data: () => ({
    selectedStudents: [],
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    students: [],
  }),
  watch: {
    selectedStudents: function (val) {
      console.log(val);
      this.callbackSelect(this.selectedStudents);
    },
  },
  created() {
    this.api.url = `${this.$api.servers.event}/api/v1/en/moderator/event/${this.$route.params.parentId}/finisher/getByStatus`;

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.students = resp.Finisher;
      this.selectedStudents = this.$_.map(this.students.slice(), "id");
    };
  },
  computed: {
    sentAllStudent() {
      return this.selectedStudents.length === this.students.length;
    },
    sentSomeStudent() {
      return this.selectedStudents.length > 0 && !this.sentAllStudent;
    },
    icon() {
      if (this.sentAllStudent) return "mdi-close-box";
      if (this.sentSomeStudent) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },

  methods: {
    fetch(){
      this.api.params = {
        status:"pending",
        institutionName: this.selectedSchool.institutionName,
        town: this.selectedSchool.town,
      };
      this.$api.fetch(this.api);
    },
    toggle() {
      this.$nextTick(() => {
        if (this.sentAllStudent) {
          this.selectedStudents = [];
        } else {
          this.selectedStudents = this.$_.map(this.students.slice(), "id");
        }
      });
    },
  },
  mounted() {
   
  },
};
</script>

<style>
</style>