<template>
  <v-row>
    <v-col
      ><v-select
        dense
        :label="$t('model.name.institution')"
        :placeholder="$t('model.name.institution')"
        class="white"
        :items="data"
        :item-text="schoolName"
        outlined
        v-model="id"
        item-value="id"
      ></v-select
    ></v-col>
  </v-row>
</template>

<script>
export default {
  props: ["callbackSelect"],
  data: () => ({
    item: null,
    id:null,
    data:[],
    selectedSchool: {},
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
  }),
  watch: {
    id: function (val) {
      this.selectedSchool = this.$_.find(this.data, function (obj) {
        return obj.id == val;
      });
      console.log(this.selectedSchool)
      this.callbackSelect(this.selectedSchool);
    },
  },
  created() {
    //BOC:[api]
    this.api.method = "get";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      this.item = resp[0];
      this.id = resp[0].id;
    };
  },
  mounted(){
this.fetch()
  },
  methods:{
    fetch() {
      this.api.url =`${this.$api.servers.event}/api/v1/en/moderator/data/finisher/institution`
      this.$api.fetch(this.api);
    },
    schoolName: (item) =>item.institutionId? `${item.institutionName} - ${item.town}`:`${item.institutionName} - ${item.town}(institution not in our system)`,
  }
};
</script>

<style>
</style>